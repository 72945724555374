import { jsx as _jsx } from "react/jsx-runtime";
import { CalendarDatesFilled, DiscordBrand, Image2Filled, MapViewFilled, ShieldFilled, TwitterFilled, } from '@zealy/icons';
export const CONDITION_ICONS = {
    level: _jsx(ShieldFilled, { className: "w-icon-md h-icon-md icon-prerequisite-level" }),
    quest: _jsx(MapViewFilled, { className: " w-icon-md h-icon-md icon-prerequisite-validated-quest" }),
    nft: _jsx(Image2Filled, { className: " w-icon-md h-icon-md icon-prerequisite-nft" }),
    role: _jsx(DiscordBrand, { className: " w-icon-md h-icon-md icon-prerequisite-role" }),
    date: _jsx(CalendarDatesFilled, { className: " w-icon-md h-icon-md icon-prerequisite-date" }),
    twitterFollowers: (_jsx(TwitterFilled, { className: " w-icon-md h-icon-md icon-prerequisite-twitter-followers" })),
};
