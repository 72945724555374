import type { ComponentProps } from 'react';
import React from 'react';

import {
  ArrowTopCircleFilled,
  GlobeFilled,
  LightningFilled,
  ThumbsUpFilled,
  TicketFilled,
} from '@zealy/icons';

import { cn } from '#utils/utils';

const RaffleOutline = (props: ComponentProps<'svg'>) => (
  <svg
    width="27"
    height="25"
    viewBox="0 0 27 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Raffle Outline</title>
    <path
      d="M4.00411 0.799313C5.0185 0.282455 6.01511 0.12435 6.81832 0.0587262C7.54026 -0.00025887 8.38479 -0.000120999 9.21433 1.44215e-05L9.21434 1.44243e-05L9.33337 2.77758e-05H9.83337C10.1191 2.77758e-05 10.3979 0.0299946 10.6667 0.0869573C10.9355 0.0299946 11.2143 2.77758e-05 11.5 2.77758e-05H17L17.1191 1.44243e-05L17.1191 1.44215e-05C17.9486 -0.000120999 18.7932 -0.00025887 19.5151 0.0587262C20.3183 0.12435 21.3149 0.282455 22.3293 0.799314C23.7092 1.50238 24.831 2.62424 25.5341 4.0041C26.0509 5.01849 26.209 6.0151 26.2747 6.81831C26.3337 7.54025 26.3335 8.3848 26.3334 9.21434L26.3334 9.33336V10.3889C26.3334 11.0891 26.1652 11.75 25.8669 12.3334C26.1652 12.9168 26.3334 13.5776 26.3334 14.2778V15.3334L26.3334 15.4524C26.3335 16.2819 26.3337 17.1265 26.2747 17.8484C26.209 18.6516 26.0509 19.6482 25.5341 20.6626C24.831 22.0425 23.7092 23.1643 22.3293 23.8674C21.3149 24.3843 20.3183 24.5424 19.5151 24.608C18.7931 24.667 17.9486 24.6668 17.1191 24.6667L17 24.6667H11.5C11.2143 24.6667 10.9355 24.6367 10.6667 24.5798C10.3979 24.6367 10.1191 24.6667 9.83337 24.6667H9.33337L9.21435 24.6667C8.38481 24.6668 7.54026 24.667 6.81832 24.608C6.01511 24.5424 5.0185 24.3843 4.00411 23.8674C2.62425 23.1643 1.50239 22.0425 0.799322 20.6626C0.282464 19.6482 0.12436 18.6516 0.0587354 17.8484C-0.000249691 17.1265 -0.00011182 16.2819 2.36006e-05 15.4524L2.36034e-05 15.4524L3.69549e-05 15.3334V14.2778C3.69549e-05 13.5776 0.168254 12.9167 0.466478 12.3334C0.168254 11.75 3.69549e-05 11.0891 3.69549e-05 10.3889V9.33336L2.36034e-05 9.21434L2.36006e-05 9.21432C-0.00011182 8.38479 -0.000249691 7.54025 0.0587354 6.81831C0.12436 6.0151 0.282465 5.01849 0.799322 4.0041C1.50239 2.62424 2.62425 1.50238 4.00411 0.799313Z"
      fill="currentColor"
    />
  </svg>
);

const AllOutline = (props: ComponentProps<'svg'>) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>All Outline</title>

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.38513 12.2471C-0.000964165 11.4315 -0.109536 10.4899 0.117554 9.57319C1.2697 4.92198 4.92176 1.26982 9.57296 0.117599C10.4897 -0.109496 11.4312 -0.000962406 12.2467 0.385071C12.3927 0.370457 12.5425 0.362776 12.6961 0.362776C12.8497 0.362776 12.9995 0.370457 13.1455 0.385071C13.961 -0.000962645 14.9026 -0.109496 15.8193 0.117599C20.4704 1.26981 24.1224 4.92187 25.2746 9.573C25.5017 10.4897 25.3932 11.4312 25.0072 12.2467C25.0218 12.3927 25.0295 12.5425 25.0295 12.6962C25.0295 12.8499 25.0218 12.9998 25.0072 13.1458C25.3932 13.9613 25.5017 14.9028 25.2746 15.8195C24.1223 20.4705 20.4703 24.1224 15.8193 25.2746C14.9026 25.5017 13.961 25.3932 13.1455 25.0071C12.9995 25.0218 12.8497 25.0294 12.6961 25.0294C12.5425 25.0294 12.3927 25.0218 12.2467 25.0071C11.4312 25.3932 10.4897 25.5017 9.57296 25.2746C4.92185 24.1224 1.26985 20.4704 0.117624 15.8193C-0.109487 14.9026 -0.000936031 13.961 0.385141 13.1454C0.370537 12.9995 0.362861 12.8497 0.362861 12.6962C0.362861 12.5427 0.370533 12.393 0.38513 12.2471Z"
      fill="currentColor"
    />
  </svg>
);

const FCFSOutline = (props: ComponentProps<'svg'>) => (
  <svg
    width="24"
    height="27"
    viewBox="0 0 24 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>FCFS Outline</title>
    <path
      d="M1.32694 9.87214C-0.751309 12.4531 -0.343838 16.2302 2.23709 18.3085C3.07462 18.9828 4.03788 19.3955 5.02835 19.557C4.83878 21.5018 5.60147 23.5011 7.23709 24.8181C9.81815 26.8964 13.5951 26.4889 15.6735 23.9079L21.8214 16.2729C23.8997 13.6919 23.4922 9.91488 20.9113 7.83658C20.0738 7.16224 19.1105 6.7496 18.12 6.588C18.3096 4.64325 17.5469 2.644 15.9113 1.32695C13.3302 -0.751331 9.55326 -0.343829 7.47489 2.23714L1.32694 9.87214Z"
      fill="currentColor"
    />
  </svg>
);

const TopOutline = (props: ComponentProps<'svg'>) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Top Outline</title>

    <circle fillRule="evenodd" clipRule="evenodd" fill="currentColor" cx="13" cy="13" r="13" />
  </svg>
);

const VoteOutline = (props: ComponentProps<'svg'>) => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Vote Outline</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.0308 18.4192C26.0254 18.4543 26.02 18.4895 26.0146 18.5247C25.9055 19.235 25.7924 19.9705 25.6463 20.5985C25.4815 21.3066 25.2165 22.1598 24.6636 23.0071C23.9008 24.1763 22.8201 25.1034 21.5485 25.6796C20.6269 26.0972 19.7433 26.2295 19.0185 26.2847C18.3755 26.3336 17.6314 26.3335 16.9127 26.3333C16.8771 26.3333 16.8415 26.3333 16.806 26.3333H11.8333C11.7937 26.3333 11.754 26.3333 11.7143 26.3333C10.8848 26.3335 10.0402 26.3336 9.31828 26.2746C8.51508 26.209 7.51846 26.0509 6.50407 25.534C6.37497 25.4683 6.24813 25.3988 6.12367 25.3258C5.71291 25.4394 5.28021 25.5 4.83333 25.5C2.16397 25.5 0 23.3361 0 20.6667V10.6667C0 7.99729 2.16396 5.83333 4.83333 5.83333C5.24217 5.83333 5.63915 5.88409 6.01831 5.97966L10.6759 1.32207C11.5224 0.475559 12.6705 0 13.8677 0C17.4229 0 20.105 2.74043 20.414 5.9466C21.1409 6.03916 21.9847 6.23044 22.8313 6.67543C24.4859 7.54512 25.7459 9.01382 26.3539 10.7814C26.7409 11.9066 26.7404 12.968 26.6809 13.7933C26.6264 14.5498 26.4904 15.4329 26.3542 16.3171C26.3482 16.3562 26.3422 16.3954 26.3362 16.4345L26.3356 16.4384L26.0308 18.4192Z"
      fill="currentColor"
    />
  </svg>
);

const Lightning = ({ value = 1, max = 1, className = '' }) => {
  const percentageLeft = 100 - (value / max) * 100;

  const id = `lightning-${percentageLeft}`;

  return (
    <svg
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('group-disabled:grayscale group-aria-disabled:grayscale', className)}
    >
      <title>`Lightning-${percentageLeft}`</title>
      <defs>
        <linearGradient id={`${id}`} gradientTransform="rotate(90)">
          <stop offset="0%" stopColor="#FFD12F4D" />
          <stop offset={`${percentageLeft}%`} stopColor="#FFD12F4D" />
          <stop offset={`${percentageLeft + 0.0001}%`} stopColor="#FFD12F" />
        </linearGradient>
      </defs>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.90887 0.0253295C8.59986 -0.0833478 9.29927 0.161992 9.77095 0.678512C10.1422 1.08503 10.2312 1.65776 10.272 2.13906C10.3159 2.65637 10.3159 3.34095 10.3159 4.18048V5.68656C10.3159 6.05034 10.3165 6.27306 10.3301 6.43969C10.3365 6.51732 10.3445 6.56428 10.3512 6.59281C10.3544 6.60637 10.357 6.61472 10.3587 6.61923C10.3602 6.62356 10.3613 6.62572 10.3613 6.62572C10.4012 6.70412 10.465 6.76786 10.5434 6.80781C10.5434 6.80781 10.5455 6.8089 10.5499 6.81045C10.5544 6.81206 10.5627 6.81474 10.5763 6.81793C10.6048 6.82465 10.6518 6.83262 10.7294 6.83896C10.896 6.85257 11.1188 6.85322 11.4826 6.85322H12.0283C12.66 6.8532 13.191 6.85319 13.6048 6.89077C14.0119 6.92775 14.4755 7.01093 14.8481 7.30834C15.344 7.70415 15.6325 8.30434 15.6318 8.9388C15.6313 9.41553 15.4066 9.82953 15.1811 10.1706C14.952 10.5171 14.6202 10.9318 14.2256 11.4251L10.3361 16.287C9.81163 16.9425 9.38399 17.4771 9.02658 17.8536C8.69404 18.204 8.26673 18.5956 7.7229 18.6811C7.03191 18.7898 6.3325 18.5445 5.86082 18.0279C5.48959 17.6214 5.40056 17.0487 5.35974 16.5674C5.31587 16.0501 5.31587 15.3655 5.31589 14.526V13.0199C5.31589 12.6561 5.31524 12.4334 5.30162 12.2668C5.29528 12.1891 5.28731 12.1422 5.2806 12.1136C5.2774 12.1001 5.27473 12.0917 5.27311 12.0872C5.27157 12.0829 5.27047 12.0807 5.27047 12.0807C5.23052 12.0023 5.16678 11.9386 5.08838 11.8986C5.08838 11.8986 5.08623 11.8975 5.0819 11.896C5.07738 11.8944 5.06904 11.8917 5.05547 11.8885C5.02695 11.8818 4.97998 11.8738 4.90236 11.8675C4.73572 11.8539 4.513 11.8532 4.14922 11.8532H3.6035C2.9718 11.8532 2.44077 11.8533 2.02702 11.8157C1.61986 11.7787 1.15622 11.6955 0.783642 11.3981C0.287784 11.0023 -0.000683839 10.4021 1.2174e-06 9.76764C0.000515953 9.29091 0.225196 8.87691 0.450665 8.53587C0.679788 8.18931 1.01153 7.77466 1.40616 7.28139L5.29568 2.41949C5.82013 1.76392 6.24777 1.22935 6.60519 0.8528C6.93773 0.502467 7.36504 0.110862 7.90887 0.0253295Z"
        fill={`url(#${id})`}
      />
    </svg>
  );
};

export const REWARD_METHOD = {
  raffle: {
    icon: TicketFilled,
    outline: RaffleOutline,
    color: 'icon-reward-method-raffle',
  },
  all: {
    icon: GlobeFilled,
    outline: AllOutline,
    color: 'icon-reward-method-all',
  },
  vote: {
    icon: ThumbsUpFilled,
    outline: VoteOutline,
    color: 'icon-reward-method-vote',
  },
  fcfs: {
    icon: Lightning,
    outline: FCFSOutline,
    simple: LightningFilled,
    color: 'icon-reward-method-fcfs',
  },
  top: {
    icon: ArrowTopCircleFilled,
    outline: TopOutline,
    color: 'icon-reward-method-top',
  },
};
