import otherRewardImage from './assets/other-reward.webp';
import roleImage from './assets/role-reward.webp';
import tokenImage from './assets/token-reward.webp';
import xpImage from './assets/xp-reward.webp';

export const REWARD_IMAGE_SOURCES = new Map<string, string>();
REWARD_IMAGE_SOURCES.set('xp', xpImage.src);
REWARD_IMAGE_SOURCES.set('role', roleImage.src);
REWARD_IMAGE_SOURCES.set('other', otherRewardImage.src);
REWARD_IMAGE_SOURCES.set('token', tokenImage.src);
