import React from 'react';
import { useTranslations } from 'next-intl';

import { REWARD_METHOD } from '#constants/RewardMethod';
import { cn } from '#utils/utils';

import type { RewardCardProps } from '../RewardCard.types';
import { styles } from './RewardMethod.styles';

export type RewardMethodProps = Pick<RewardCardProps, 'className' | 'size' | 'method' | 'max'> & {
  value?: number;
};

export const RewardMethod = ({
  size = 'sm',
  method,
  value = 0,
  max = 0,
  className,
}: RewardMethodProps) => {
  const t = useTranslations('quest.methods.type');
  const title = t(`${method}.card-title`, {
    max,
    value,
  });

  return (
    <div className={cn(styles.root({ size }), className)}>
      <div className="relative flex justify-center items-center overflow-visible">
        {REWARD_METHOD[method]?.icon({
          className: cn(styles.icon({ size }), REWARD_METHOD[method].color, 'z-10'),
          value,
          max,
        })}
        {size === 'sm' &&
          REWARD_METHOD[method]?.outline({
            className: 'icon-[var(--bg-color)] absolute',
          })}
      </div>
      <span className={styles.label({ size })}>{title}</span>
    </div>
  );
};
