import { cva } from 'class-variance-authority';

const root = cva(['flex gap-reward-card-reward-method z-10'], {
  variants: {
    size: {
      sm: 'absolute -top-100 -left-100',
      md: '',
    },
  },
  defaultVariants: {
    size: 'sm',
  },
});

const label = cva(['reward-card-reward-method text-reward-card-primary'], {
  variants: {
    size: {
      sm: 'hidden',
      md: '',
    },
  },
  defaultVariants: {
    size: 'sm',
  },
});

const icon = cva(
  ['group-disabled:icon-reward-card-disabled group-aria-disabled:icon-reward-card-disabled'],
  {
    variants: {
      size: {
        sm: 'w-reward-card-icon-sm h-reward-card-icon-sm',
        md: 'w-reward-card-icon-md h-reward-card-icon-md',
      },
    },
    defaultVariants: {
      size: 'sm',
    },
  },
);

export const styles = {
  root,
  label,
  icon,
};
