import { type GetQuestOutput } from '@zealy/utils';

export const flattenRewardMethod = (rewardMethod: GetQuestOutput['rewards'][number]['method']) => {
  switch (rewardMethod?.type) {
    case 'all':
      return { method: 'all' } as const;
    case 'fcfs':
      return { method: 'fcfs', max: rewardMethod.max, claimed: rewardMethod.count } as const;
    case 'raffle':
      return { method: 'raffle', max: rewardMethod.amount } as const;
    case 'top':
      return { method: 'top', max: rewardMethod.topN } as const;
    case 'vote': {
      return { method: 'vote', max: rewardMethod.amount } as const;
    }
    default:
      return {
        method: 'all',
      } as const;
  }
};
