import { useEffect } from 'react';
import { useFormatter, useNow, useTranslations } from 'next-intl';

import { Badge } from '@zealy/design-system';
import { CheckLine, CrossLine, Lock2Line } from '@zealy/icons';
import { questKeys } from '@zealy/queries';
import { dayjs, getSubdomain } from '@zealy/utils';

import { queryClient } from '#app/QueryProvider';
import { COOLDOWN, REWARDS } from '#constants/quests/Properties';

import type { QuestData } from '../../QuestCard.types';
import { getQuestStatus } from './QuestStatus.utils';

const BadgeVariant = {
  'in-review': { icon: <CheckLine />, variant: 'neutral' },
  completed: { icon: <CheckLine />, variant: 'positive' },
  'claim-rewards': { icon: REWARDS.icon, variant: 'positive' },
  'no-retry': { icon: <CrossLine />, variant: 'disabled' },
  'retry-after': { icon: COOLDOWN.icon, variant: 'neutral' },
  lock: { icon: <Lock2Line />, variant: 'negative' },
  'unlock-in': { icon: <Lock2Line />, variant: 'negative' },
} as const;

export const QuestStatus = (props: QuestData) => {
  const t = useTranslations();
  const format = useFormatter();

  const status = getQuestStatus(props, false);

  const now = useNow({
    updateInterval: status.unlockAt && dayjs(status.unlockAt).isAfter(dayjs()) ? 1000 : 0,
  });

  useEffect(() => {
    if (status.unlockAt && dayjs(status.unlockAt).isBefore(dayjs().add(1, 'hour'))) {
      const to = setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: questKeys.board(getSubdomain()) });
      }, dayjs(status.unlockAt).diff(dayjs()));

      return () => clearTimeout(to);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!status.reason) return null;

  const { icon, variant } = BadgeVariant[status.reason];

  return (
    <div className="border-4 border-[var(--color-bg-module-card-default)] rounded-md bg-secondary">
      <Badge icon={icon} variant={variant} size="xs">
        {t(`questboard.badge.statuses.${status.reason}`, {
          date: status.unlockAt ? format.relativeTime(new Date(status.unlockAt), now) : undefined,
        })}
      </Badge>
    </div>
  );
};
