import type { QuestData } from '../../QuestCard.types';

export enum QuestStatusReason {
  CONDITION_NOT_MET_YET = 'unlock-in',
  CONDITION_NOT_MET = 'lock',
  COOLDOWN = 'retry-after',
  NO_RETRY = 'no-retry',
  CLAIM_REWARDS = 'claim-rewards',
  IN_REVIEW = 'in-review',
  COMPLETED = 'completed',
}

export type QuestStatus = {
  /**
   * Controlling the status of the claim button
   */
  canClaim: boolean;
  /**
   * If you can see the content of the quest
   */
  canInspect?: boolean;
  /**
   * If card click is disabled
   */
  disabled?: boolean;
  /**
   * Reason for the status
   */
  reason?: QuestStatusReason;
  /**
   * Unlock date if the status is completed or cooldown
   */
  unlockAt?: Date | null;
};

export const getQuestStatus = (
  quest: QuestData,
  isSubscriptionLimitReached: boolean,
): QuestStatus => {
  if (quest.locked || isSubscriptionLimitReached) {
    const totalLocked = quest.conditions?.filter(({ fulfilled }) => !fulfilled).length;
    const futureDateConditions = quest.conditions
      ?.filter(
        condition =>
          condition.type === 'date' &&
          condition.operator === '>' &&
          new Date(condition.value) > new Date() &&
          !condition.fulfilled,
      )
      .sort((a, b) => new Date(a.value).getTime() - new Date(b.value).getTime());

    if (futureDateConditions?.length && totalLocked === futureDateConditions?.length) {
      return {
        canClaim: false,
        disabled: true,
        reason: QuestStatusReason.CONDITION_NOT_MET_YET,
        unlockAt: futureDateConditions.length ? new Date(futureDateConditions[0].value) : null,
      };
    }

    return {
      canClaim: false,
      disabled: true,
      reason: QuestStatusReason.CONDITION_NOT_MET,
    };
  }
  if (quest.completed) {
    const rewardsToClaim =
      quest.completed && quest.rewards?.some(({ status }) => status === 'claim-available');

    if (rewardsToClaim) {
      return {
        canClaim: false,
        reason: QuestStatusReason.CLAIM_REWARDS,
      };
    }

    return {
      canClaim: false,
      canInspect: true,
      reason: QuestStatusReason.COMPLETED,
      unlockAt: !quest.opened && quest.openAt ? new Date(quest.openAt) : null,
    };
  }

  if (quest.inReview && !quest.opened) {
    return {
      canClaim: false,
      reason: QuestStatusReason.IN_REVIEW,
      unlockAt: !quest.opened && quest.openAt ? new Date(quest.openAt) : null,
    };
  }

  if (!quest.canRetry || quest.canRetry === 'never') {
    return {
      canInspect: !!quest.retryAt,
      canClaim: false,
      reason: quest.retryAt ? QuestStatusReason.COOLDOWN : QuestStatusReason.NO_RETRY,
      unlockAt: quest.retryAt ? new Date(quest.retryAt) : null,
    };
  }

  return {
    canClaim: true,
    canInspect: true,
  };
};
