import { cva } from 'class-variance-authority';

const root = cva(['box-border flex-shrink-0 relative group disabled:cursor-not-allowed'], {
  variants: {
    size: {
      sm: 'w-reward-card-img-sm h-reward-card-img-sm relative',
      md: [
        'p-reward-card-md rounded-reward-card-md',
        'flex flex-col gap-reward-card-md',
        'bg-reward-card hover:bg-reward-card-hover active:bg-reward-card-press outline-none focus-visible:ring-reward-card-focus',
        'disabled:bg-reward-card',
        'transition-colors duration-200 ease-in-out',
      ],
    },
  },
  defaultVariants: {
    size: 'sm',
  },
});

const image = cva(
  [
    'object-cover rounded-reward-card-img-md overflow-hidden bg-[#B3BCCF] group-disabled:grayscale group-aria-disabled:grayscale flex items-center justify-center',
  ],
  {
    variants: {
      size: {
        sm: 'w-reward-card-img-sm h-reward-card-img-sm',
        md: 'w-1400 h-1400',
      },
    },
    defaultVariants: {
      size: 'sm',
    },
  },
);

const overlay = cva(['absolute inset-0 bg-primary opacity-[.45]'], {
  variants: {
    size: {
      sm: 'rounded-reward-card-container',
      md: 'rounded-reward-card-img-md',
    },
  },
  defaultVariants: {
    size: 'sm',
  },
});

const labelContainer = cva(['absolute inset-0 overflow-hidden'], {
  variants: {
    size: {
      sm: 'rounded-reward-card-container rounded-reward-card-img-md',
      md: '',
    },
  },
  defaultVariants: {
    size: 'sm',
  },
});

const label = cva(
  [
    'text-onbrand-primary absolute overflow-hidden text-center max-w-[104%] inline-block whitespace-nowrap text-ellipsis line-clamp-1 pointer-events-none left-[50%] translate-x-[-50%] px-100 py-25 border-t-[0.5px] border-x-[0.5px] bottom-0 border-[#C88CBE] bg-primary bg-gradient-to-b from-[#593473] to-[#422357]',
  ],
  {
    variants: {
      size: {
        sm: 'rounded-t-component-sm tag-xs',
        md: 'rounded-t-component-md tag-md',
      },
      negativeReward: {
        true: 'from-error-secondary-press to-error-secondary-hover border-error-secondary text-onerror-secondary',
      },
    },
    defaultVariants: {
      size: 'sm',
    },
  },
);

export const styles = {
  root,
  image,
  overlay,
  labelContainer,
  label,
};
